// i18next-extract-mark-ns-start support-software-updates

import { LINKS } from '@utils/constants';
import { ISoftwareUpdateFrontmatter } from '@utils/types';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Deck from '@components/Deck';
import PageSection from '@components/PageSection';
import Tile from '@components/Tile';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const SoftwareUpdatesPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__tiles  section--padding--adjust"
				layout="wide">
				<Deck columns={4}>
					{data.releases.edges.map(
						(
							{ node }: { node: ISoftwareUpdateFrontmatter },
							i: number
						) => (
							<Tile
								key={i}
								title={node.frontmatter.title}
								type="plain"
								link={`${LINKS.SUPPORT_SOFTWARE_UPDATES}${node.fields.slug}`}>
								{node.frontmatter.title}
							</Tile>
						)
					)}
				</Deck>
			</PageSection>
		</Page>
	);
};

export default SoftwareUpdatesPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-software-updates", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/software-hero.jpg" }) {
			...imageBreaker
		}
		releases: allMarkdownRemark(
			filter: { 
				fileAbsolutePath: { regex: "/software-updates/" } 
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
		) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						summary
					}
				}
			}
		}
	}
`;
